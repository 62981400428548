const monthNumberHebrewMap = {
	1: "ינואר",
	2: "פברואר",
	3: "מרץ",
	4: "אפריל",
	5: "מאי",
	6: "יוני",
	7: "יולי",
	8: "אוגוסט",
	9: "ספטמבר",
	10: "אוקטובר",
	11: "נובמבר",
	12: "דצמבר",
};

const BASE_URL = "//sbs.server.barpahima.co.il/api/";

export const globalVariable = {
	monthNumberHebrewMap,
	BASE_URL,
};
